import axios from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { mapDbUserToUser } from '../mappers';
import { User, UserApplication, WebToken } from "../types";

export interface IUserContext {
  user: User | undefined;
  authenticateUser: (credential?: string) => void;
  loading: boolean;
  createUser: (user: User) => void;
  getUserById: (userId: string) => Promise<User | undefined>;
  createUserApplication: (userId: string, application: UserApplication) => void;
  logout: () => void;
}

export const UserContext = createContext<IUserContext | undefined>(undefined);

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User>();
  const [token, setToken] = useState<string>();
  const [loading, setLoading] = useState(false);
  
  const browserCacheTokenName = 'memory-vault-token';
  const baseUrl = 'https://memory-vault.herokuapp.com/api';
  const specialCharacters = '!"#$%&()*-.:;<=>?@[\\]^_';

  const createUser = useCallback((user: User) => {
    axios.post(`${baseUrl}/createUser`, {
      ...user
    })

    setUser(user);
  }, [baseUrl]);

  const getUserById = useCallback(async (userId: string) => {
    const response = await axios.get(`${baseUrl}/getUserById`, {
      params: {
        id: userId
      }
    });

    console.log(response.data);

    if (response.data.length > 0) {
      return mapDbUserToUser(response.data[0]);
    }
    else {
      return undefined;
    }
  }, [baseUrl]);

  const authenticateUser = useCallback(async (credential?: string) => {
    if (!credential) {
      return;
    }

    setLoading(true);

    setToken(credential);
    const decodedToken = jwtDecode(credential) as WebToken;
    let currentUser = await getUserById(decodedToken.sub)

    if (!currentUser) {
      let randomSymbols = specialCharacters.split('');
      let characters = '';
      for (let i = 0; i < 5; i++) {
        characters += randomSymbols.splice(Math.floor(Math.random() * randomSymbols.length), 1)
      }

      currentUser = {
        id: decodedToken.sub,
        picture: decodedToken.picture,
        magicNumber: (Math.floor(Math.random() * 9) + 1),
        symbols: characters.split('')
      } as User;

      createUser(currentUser);
    }

    setUser(currentUser);
    setLoading(false);
  }, [createUser, getUserById]);

  const createUserApplication = (userId: string, userApplication: UserApplication) => {
    axios.post(`${baseUrl}/createApplication`, {
      ...userApplication
    })
  }

  const logout = () => {
    localStorage.removeItem(browserCacheTokenName);
    setToken(undefined);
  }

  useEffect(() => {
    if (!token) {
      setUser(undefined);
      return;
    }

    localStorage.setItem(browserCacheTokenName, token);
  }, [token])

  useEffect(() => {
    if (!user) {
      const credential = localStorage.getItem(browserCacheTokenName);
      if (credential) {
        authenticateUser(credential);
      }
    }
  }, [user, authenticateUser])

  return (
    <UserContext.Provider
      value={{
        user,
        authenticateUser,
        loading,
        createUser,
        getUserById,
        createUserApplication,
        logout
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context) return context;

  throw Error("Login details context was not registered");
};
