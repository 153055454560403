import { Grid, Paper } from '@mui/material';

export const Home = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <h1>
            Welcome to Memory Vault!
          </h1>
          <h2>
            Introduction
          </h2>
          <p>
            This site creates easy to remember passwords, helping you to store them and retrieve them from your memory easily.
            It does this by letting you choose common memory questions, to which most people have a unique and hard to guess answer.
            You can also use mnemonic techniques, if you need them, to create 'memory keys' as hints only you will know the answer to.
          </p>
          <p>
            Each set of answers is connected to a separate login.
          </p>

          <h2>
            Process
          </h2>
          <p>
            We generate a set of memory questions using our collection of prompts, but you can add your own too.
            Then there are three steps to create a password, the last of which creates a hint and mnemonic story to help you recall your login.
          </p>
          <p>
            Providing you don't share any of your answers on social media this method creates passwords which are extremely hard to hack.
            Even better, we don't store any of your actual passwords, so we're immune to breaches!
          </p>
          <p>
            To begin, open the sidebar using the burger menu and click 'New Password'.
          </p>
        </Paper>
      </Grid>
    </Grid>
  );
}
