import { FormControl, FormHelperText, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { getOrdinal } from "./helpers";

interface NumberedPromptWithAnswerProps {
  index: number;
  itemType: string;
  prompt?: { prompt: number, answer: string };
  updateMap: (key: number, value: { prompt: number, answer: string }) => void;
}

export const NumberedPromptWithAnswer = ({ index, itemType, prompt, updateMap }: NumberedPromptWithAnswerProps) => {
  const [promptNumber, setPromptNumber] = useState(prompt?.prompt ?? 0);
  const [answer, setAnswer] = useState(prompt?.answer ?? '');

  const getLabel = (index: number) => `${getOrdinal(index)} item number`;
  const getHelperText = (index: number) => `${getOrdinal(index)} item number in your ${itemType}`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={4}>
        <FormControl
          variant="outlined"
          fullWidth
          style={{ marginBottom: "1em" }}
        >
          <TextField
            id={`item-${index}`}
            label={getLabel(index + 1)}
            value={promptNumber}
            type='number'
            onChange={(event: any) => {
              setPromptNumber(event.target.value);
              updateMap(index, { prompt: event.target.value, answer });
            }}
          />
          <FormHelperText id={`item-${index}-helper-text`}>
              {getHelperText(promptNumber)}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        <FormControl variant='outlined' fullWidth style={{ marginBottom: "1em" }}>
          <TextField
            id={`answer-${index}`}
            value={answer}
            label='Answer'
            onChange={(event: any) => {
              setAnswer(event.target.value);
              updateMap(index, { prompt: promptNumber, answer: event.target.value });
            }} />
        </FormControl>
      </Grid>
    </Grid>
  );
}