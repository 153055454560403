import { CssBaseline, Grid } from "@mui/material";
import { useEffect, useRef } from "react";
import { useUserContext } from "./Contexts/UserContext"
import styles from "./SettingsForPrinting.module.css"

interface SettingsForPrintingProps {
}

export const SettingsForPrinting = ({ }: SettingsForPrintingProps) => {
  const { user } = useUserContext();

  const printDialogOpen = useRef(false);

  useEffect(() => {
    if (!printDialogOpen.current) {
      window.print();
    }
    
    printDialogOpen.current = true;
  }, [])

  return (
    <>
      <CssBaseline />
      <Grid
        container
        spacing={3}
      >
        <Grid item xs={12}>
          <h1>
            Memory Vault Master Keys
          </h1>
          <p style={{ fontSize: '18pt', color: 'red' }}>
            Keep this printout safe and don't show it to anyone.
          </p>
          <p>
            Don't keep a copy of it online or take a photograph of it. <br />
            Ideally you should commit it to memory.
          </p>
        </Grid>
        <Grid item xs={12} style={{ fontSize: '18pt' }}>
          <p>
            <div className={styles.characterLocationDescription}>
              Before first word:
            </div>
            <strong> {user?.symbols[0]} </strong>
          </p>
          <p>
            <div className={styles.characterLocationDescription}>
              After first word:
            </div>
            <strong> {user?.symbols[1]} </strong>
          </p>
          <p>
            <div className={styles.characterLocationDescription}>
              After second word:
            </div>
            <strong> {user?.symbols[2]} </strong>
          </p>
          <p>
            <div className={styles.characterLocationDescription}>
              After third word:
            </div>
            <strong> {user?.symbols[3]} </strong>
          </p>
          <p style={{ marginTop: '1em' }}>
            <div className={styles.characterLocationDescription}>
              Your magic number:
            </div>
            <strong> {user?.magicNumber} </strong>
          </p>
        </Grid>
        <Grid item xs={12}>
          <p>
            Remember: the first letter in your password is capitalised.
          </p>
        </Grid>
      </Grid>
    </>
  )
}