import axios from "axios";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { mapDbUserApplicationsToUserApplications, mapUserApplicationToDbUserApplication } from "../mappers";
import { UserApplication } from "../types";
import { useUserContext } from "./UserContext";

export interface IUserApplicationsContext {
  userApplications: UserApplication[];
  userApplication: UserApplication | undefined;
  createUserApplication: (userApplication: UserApplication) => void;
  pickUserApplication: (applicationName: string) => void;
  removeUserApplication: (applicationName: string) => void;
  error: string | undefined;
  clearError: () => void;
}

export const UserApplicationsContext = createContext<IUserApplicationsContext | undefined>(undefined);

export const UserApplicationsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useUserContext();

  const [userApplications, setUserApplications] = useState<UserApplication[]>([]);
  const [userApplication, setUserApplication] = useState<UserApplication>();
  const [error, setError] = useState<string>();

  const baseUrl = 'https://memory-vault.herokuapp.com/api';

  const createUserApplication = (userApplication: UserApplication) => {
    if (!user) {
      return;
    }

    axios.post(`${baseUrl}/createUserApplication`, {
      user_id: user.id,
      ...mapUserApplicationToDbUserApplication(userApplication)
    })
      .then(_ => {
        refreshUserApplications();
      })
      .catch(response => {
        setError(response.response.data);
        setTimeout(clearError, 5000);
      });
  }

  const getApplications = useCallback(async () => {
    if (!user) {
      return;
    }

    const response = await axios.get(`${baseUrl}/getUserApplications`, {
      params: {
        userId: user.id
      }
    });
    return response.data;
  }, [user, baseUrl])

  const refreshUserApplications = useCallback(() => {
    getApplications().then(applications => {
      if (!applications?.rows) {
        return;
      }

      const mappedApplications = mapDbUserApplicationsToUserApplications(applications.rows) as UserApplication[];
      setUserApplications(mappedApplications);
    });
  }, [getApplications, setUserApplications])

  const pickUserApplication = (applicationName: string) => {
    const application = userApplications.filter(ua => ua.applicationName === applicationName)[0];

    setUserApplication(application ?? {
      applicationName: 'Unknown',
      login: 'Unknown',
      loginItems: [],
      question: 'Unknown',
      prompts: [],
      passwordLength: 0
    } as UserApplication);
  }

  const removeUserApplication = async (applicationName: string) => {
    if (!user || !applicationName) {
      return;
    }

    axios.post(`${baseUrl}/removeUserApplication`, {
      userId: user.id,
      applicationName
    })
      .then(_ => {
        refreshUserApplications();
      })
      .catch(response => {
        setError(response.response.data);
        setTimeout(clearError, 5000);
      });
  }

  const clearError = () => {
    setError(undefined);
  }

  useEffect(() => {
    refreshUserApplications();
  }, [refreshUserApplications]);

  return (
    <UserApplicationsContext.Provider
      value={{
        userApplications,
        userApplication,
        createUserApplication,
        pickUserApplication,
        removeUserApplication,
        error,
        clearError
      }}
    >
      {children}
    </UserApplicationsContext.Provider>
  );
}

export const useUserApplicationsContext = () => {
  const context = useContext(UserApplicationsContext);
  if (context) return context;

  throw Error("Login details context was not registered");
};
