import { DatabaseUser, DatabaseUserApplication, User, UserApplication } from "./types";

export const mapUserToDbUser = (value: User) => {
  if (!value) {
    return undefined;
  }

  return {
    id: value.id,
    picture: value.picture,
    magic_number: value.magicNumber,
    symbols: JSON.stringify(value.symbols)
  } as DatabaseUser;
}

export const mapUserApplicationToDbUserApplication = (value: UserApplication) => {
  if (!value) {
    return undefined;
  }
  
  return {
    application_name: value.applicationName,
    login: value.login,
    login_items: JSON.stringify(value.loginItems),
    question: value.question,
    prompts: JSON.stringify(value.prompts),
    password_length: value.passwordLength
  } as DatabaseUserApplication;
}

export const mapUserApplicationsToDbUserApplications = (values: UserApplication[]) => {
  if (!values) {
    return [];
  }
  
  return values.map(mapUserApplicationToDbUserApplication).filter(app => app !== undefined);
}

export const mapDbUserToUser = (dbValue: DatabaseUser) => {
  if (!dbValue) {
    return undefined;
  }

  return {
    id: dbValue.id,
    picture: dbValue.picture,
    symbols: JSON.parse(dbValue.symbols),
    magicNumber: dbValue.magic_number
  } as User;
}

export const mapDbUserApplicationToUserApplication = (dbValue: DatabaseUserApplication) => {
  if (!dbValue) {
    return undefined;
  }
  
  return {
    applicationName: dbValue.application_name,
    login: dbValue.login,
    loginItems: JSON.parse(dbValue.login_items) as string[],
    question: dbValue.question,
    prompts: JSON.parse(dbValue.prompts),
    passwordLength: dbValue.password_length
  } as UserApplication;
}

export const mapDbUserApplicationsToUserApplications = (dbValues: DatabaseUserApplication[]) => {
  if (!dbValues) {
    return [];
  }
  
  return dbValues.map(mapDbUserApplicationToUserApplication).filter(app => app !== undefined);
}