import { Badge, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import { AppBar } from "./AppBar";
import { Box } from "@mui/system";
import { useUserContext } from "./Contexts/UserContext";
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import React, { useState } from "react";

interface HeaderProps {
  drawerOpen: boolean;
  toggleDrawer: () => void;
}

export const Header = ({ drawerOpen, toggleDrawer }: HeaderProps) => {
  const { user, logout } = useUserContext();

  const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>();
  const userMenuOpen = Boolean(anchorElement);

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  }

  const handleUserMenuClose = () => {
    setAnchorElement(undefined);
  }

  const handleLogout = () => {
    logout();
    handleUserMenuClose();
  }

  return (
    <AppBar position="absolute" open={drawerOpen}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(drawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          Welcome!
        </Typography>
        <IconButton 
          color="inherit"
          onClick={handleUserMenuOpen}
          aria-controls={userMenuOpen ? 'user-menu' : undefined}
          aria-haspopup={true}
          aria-expanded={userMenuOpen ? true : undefined}>
          <Badge badgeContent={0} color="secondary">
            {user?.picture ? (
              <Box
                component="img"
                sx={{
                  borderRadius: '50%',
                  height: 50,
                  width: 50,
                  maxHeight: { xs: 50, md: 100 },
                  maxWidth: { xs: 50, md: 100 },
                }}
                src={user.picture} />
            ) : (
              <PersonIcon />
            )}
          </Badge>
        </IconButton>
        <Menu
          id='user-menu'
          anchorEl={anchorElement}
          open={userMenuOpen}
          onClose={handleUserMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
