import { Button } from "@mui/material";

interface PartOfSpeechButtonProps {
  item: string;
  setter: (item: string) => void;
  isSelected: boolean;
}

export const PartOfSpeechButton = ({ item, setter, isSelected }: PartOfSpeechButtonProps) => {
  const variant = isSelected ? 'contained' : 'outlined';

  return (
    <Button
      sx={{ ml: "0.2em", p: "0.1em 0.5em 0 0.5em" }}
      variant={variant}
      color='secondary'
      onClick={_ => setter(item)}>{item}
    </Button>
  );
}