import { ThemeProvider } from '@emotion/react';
import { UserContextProvider } from './Contexts/UserContext';
import { UserApplicationsContextProvider } from './Contexts/UserApplicationsContext';
import { Box, createTheme, CssBaseline } from '@mui/material';
import { Main } from './Main';

import './App.css';

const mdTheme = createTheme();

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={mdTheme}>
        <UserContextProvider>
          <UserApplicationsContextProvider>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <Main />
            </Box>
          </UserApplicationsContextProvider>
        </UserContextProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
