import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { useMemo, useState } from 'react';
import {
  correlatives,
  modifiers,
  articles,
  adjectives,
  nouns,
  prompts,
} from './constants';
import { PartOfSpeechButton } from './PartOfSpeechButton';
import { DropdownPromptWithAnswer } from './DropdownPromptWithAnswer';
import { UserApplication } from './types';
import { NumberedPromptWithAnswer } from './NumberedPromptWithAnswer';
import { getOrdinal } from './helpers';
import { useUserContext } from './Contexts/UserContext';
import { useUserApplicationsContext } from './Contexts/UserApplicationsContext';

interface NewPasswordProps {
}

export const NewPassword = ({ }: NewPasswordProps) => {
  const { user } = useUserContext();
  const { createUserApplication, error, clearError } = useUserApplicationsContext();

  const intialLoginItemsMap = new Map([
    [0, { prompt: 1, answer: '' }],
    [1, { prompt: 2, answer: '' }]
  ]);

  const [step, setStep] = useState(1);
  const [applicationName, setApplicationName] = useState('');
  const [login, setLogin] = useState('');
  const [helpCreateLogin, setHelpCreateLogin] = useState(false);
  const [loginItemsMap, setLoginItemsMap] = useState(intialLoginItemsMap);

  const [correlative, setCorrelative] = useState('');
  const [modifier, setModifier] = useState('');
  const [article, setArticle] = useState('');
  const [adjective, setAdjective] = useState('');
  const [noun, setNoun] = useState('');

  const [promptsMap, setPromptsMap] = useState(new Map());

  const updateLoginFromItemsMap = () => {
    setLogin(Array.from(loginItemsMap.values()).map(v => v.answer).join('').toLowerCase().trim().substring(0, 12))
  }

  const updateLoginItemsMap = (
    key: number,
    value: { prompt: number, answer: string }
  ) => {
    setLoginItemsMap((currentMap) => currentMap.set(key, value));
    updateLoginFromItemsMap();
  };

  const updatePromptsMap = (
    key: number,
    value: { prompt: number; answer: string }
  ) => {
    setPromptsMap((currentMap) => {
      currentMap.set(key, value);
      return new Map(Array.from(currentMap).sort((a, b) => a < b ? -1 : 1));
    });
  };

  const onBack = () => {
    if (step > 1) {
      setStep((currentStep) => currentStep - 1);
      clearError();
    }
  };

  const onNext = () => {
    if (step < 4) {
      setStep((currentStep) => currentStep + 1);
      clearError();
    } else {
      onSubmit();
    }
  };

  const onReset = () => {
    if (step === 1) {
      setApplicationName('');
      setLogin('');
      setHelpCreateLogin(false);
      setLoginItemsMap(intialLoginItemsMap);
    }

    if (step === 2) {
      setCorrelative('');
      setModifier('');
      setArticle('');
      setAdjective('');
      setNoun('');
    }

    if (step === 3) {
      setPromptsMap(new Map());
    }

    clearError();
  };

  const question = useMemo(
    () =>
      `${correlative} ${modifier} ${article} ${adjective} ${noun}`
        .toLowerCase()
        .replace(/[a-z]/i, (letter) => letter.toUpperCase())
        .trim() + '?',
    [correlative, modifier, article, adjective, noun]
  );

  const concatenatedPrompts = useMemo(() => {
    const answers = Array
      .from(promptsMap.values())
      .map((v) => v.answer);

    if (!answers || answers.length === 0) {
      return '';
    }

    return answers
      .reduce((password, answer) => password.concat(answer, user?.symbols[0]), user?.symbols[0])
      .toLowerCase()
      .replaceAll(/ /g, '');
  }, [promptsMap, user?.symbols])

  const finalPassword = useMemo(() => {
    if (!user ) {
      return;
    }

    // Find the index of the first alphabetic character
    let i = 0;
    while (i < concatenatedPrompts.length && !/[a-z]/.test(concatenatedPrompts[i])) {
      i++;
    }

    // Construct the final password
    let password = concatenatedPrompts.slice(0, i);
    password += concatenatedPrompts.charAt(i).toUpperCase();
    password += concatenatedPrompts.slice(i + 1);

    password += user.magicNumber.toString();

    return password;
  }, [user, concatenatedPrompts]);

  const onSubmit = () => {
    const userApplication = {
      applicationName,
      login: helpCreateLogin ? '' : login,
      loginItems: Array.from(loginItemsMap.values()).filter(v => v.answer).map(v => getOrdinal(v.prompt)),
      question,
      prompts: Array.from(promptsMap.values()).map((v) => prompts[v.prompt]),
      passwordLength: finalPassword!.length
    } as UserApplication;

    createUserApplication(userApplication);
  };


  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h1>New Password</h1>
          <h2>Step {step}</h2>
          {step === 1 && (
            <>
              <h3>Application Name</h3>
              <FormControl
                variant='outlined'
                fullWidth
                style={{ marginBottom: '1em' }}
              >
                <TextField
                  id='application'
                  label='What are you logging into?'
                  aria-describedby='The name of the system you will be logging into'
                  value={applicationName}
                  onChange={(e) => setApplicationName(e.target.value)}
                />
                <FormHelperText id='application-helper-text'>
                  e.g. Amazon, Netflix, Youtube
                </FormHelperText>
              </FormControl>
              <h3>Login</h3>
              <FormControl
                variant='outlined'
                fullWidth
                style={{ marginBottom: '1em' }}
              >
                <TextField
                  id='login'
                  label='What do you use as your login?'
                  aria-describedby='The text which the system uses to identify you'
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
                <FormHelperText id='login-helper-text'>
                  e.g. An email address, a username, or an account number
                </FormHelperText>
              </FormControl>
              <FormControlLabel
                style={{ marginBottom: '1em' }}
                label='Help me create a login'
                value={helpCreateLogin}
                control={
                  <Checkbox
                    id='create-login'
                    aria-describedby='Check this box if you want to use the login creator'
                    checked={helpCreateLogin}
                    onChange={(e) => {
                      setHelpCreateLogin(e.target.checked);

                      if (e.target.checked) {
                        updateLoginFromItemsMap();
                      }
                    }}
                  />
                }
              />
              {helpCreateLogin &&
                <>
                  <h3>
                    Login Generation
                  </h3>
                  <p>
                    Imagine yourself in a room you know well. We'll call this your <strong>memory room</strong>.
                  </p>
                  <p>
                    Scanning left to right <strong>from memory</strong>, number each thing you see in your mind.
                    Then, below, pick the number of the thing you want to use and type in what it is.
                  </p>
                  <hr />
                  {[0, 1].map((index: number) => (
                    <NumberedPromptWithAnswer
                      key={index}
                      index={index}
                      itemType={'memory room'}
                      prompt={loginItemsMap.get(index)}
                      updateMap={updateLoginItemsMap}
                    />
                  ))}
                </>
              }
            </>
          )}
          {step === 2 && (
            <>
              <h3>Question</h3>
              <p>
                Select some prompts from the lists below and build a question to
                which you have a memorable answer.
              </p>
              <hr />
              <FormControl
                variant='outlined'
                fullWidth
                style={{ marginBottom: '1em' }}
              >
                <TextField
                  id='question'
                  label='Click below to form a question'
                  value={question}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <h5>Question Type</h5>
                  {correlatives.map((item: string) => (
                    <PartOfSpeechButton
                      key={`${item}-${crypto.randomUUID()}`}
                      item={item}
                      setter={setCorrelative}
                      isSelected={item === correlative}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <h5>Modifier</h5>
                  {modifiers.map((item: string) => (
                    <PartOfSpeechButton
                      key={`${item}-${crypto.randomUUID()}`}
                      item={item}
                      setter={setModifier}
                      isSelected={item === modifier}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <h5>Article</h5>
                  {articles.map((item: string) => (
                    <PartOfSpeechButton
                      key={`${item}-${crypto.randomUUID()}`}
                      item={item}
                      setter={setArticle}
                      isSelected={item === article}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <h5>Adjective</h5>
                  {adjectives.map((item: string) => (
                    <PartOfSpeechButton
                      key={`${item}-${crypto.randomUUID()}`}
                      item={item}
                      setter={setAdjective}
                      isSelected={item === adjective}
                    />
                  ))}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <h5>Noun</h5>
                  {nouns.map((item: string) => (
                    <PartOfSpeechButton
                      key={`${item}-${crypto.randomUUID()}`}
                      item={item}
                      setter={setNoun}
                      isSelected={item === noun}
                    />
                  ))}
                </Grid>
              </Grid>
            </>
          )}
          {step === 3 && (
            <>
              <h3>Prompts</h3>
              <p>
                Select 3 prompts from the lists below and write in answers for
                each that are personal to you. These will be used to create your
                password, but will not be saved.
              </p>
              <hr />
              <p>
                Your question was: <strong>{question}</strong>
              </p>
              <hr />
              {[0, 1, 2].map((index: number) => (
                <>
                  <DropdownPromptWithAnswer
                    key={index}
                    index={index}
                    prompt={promptsMap.get(index)}
                    updateMap={updatePromptsMap}
                  />
                  <hr />
                </>
              ))}
            </>
          )}
          {step === 4 && (
            <>
              <h3>Results</h3>
              <p>
                Using the information you've entered, we now have the following:
              </p>
              <p>
                <strong>Question:</strong> {question}
              </p>
              <p>
                <strong>Prompts:</strong>{' '}
                {Array.from(promptsMap.values())
                  .map((v) => prompts[v.prompt])
                  .join(', ')}
              </p>
              <hr />
              <p>
                Your special symbol is: <strong>{user!.symbols.join('; ')}</strong>.
                Using those to enclose your answers creates a password: <strong>{concatenatedPrompts}</strong>
              </p>
              <hr />
              <h3>New Password</h3>
              <p>
                Most web sites require a capital letter and a number. For this reason, we will make a couple of changes:
              </p>
              <ul>
                <li>Capitalise the first letter</li>
                <li>Add your magic number to the end</li>
              </ul>
              <p>
                So your new password is: <strong style={{ fontSize: "larger" }}>{finalPassword}</strong>
              </p>
              <hr />
              {error &&
                <Alert severity='error'>
                  {error}
                </Alert>
              }
            </>
          )}
          <div
            style={{
              textAlign: 'right',
              marginTop: '2em',
            }}
          >
            <Button
              onClick={onReset}
              variant='outlined'
              style={{ float: 'left' }}
            >
              Reset
            </Button>
            <Button
              onClick={onNext}
              variant='contained'
              style={{ float: 'right', marginLeft: '2em' }}
            >
              {step < 4 ? 'Next' : 'Save'}
            </Button>
            {step > 1 && (
              <Button
                color='secondary'
                onClick={onBack}
                variant='contained'
                style={{ float: 'right' }}
              >
                Back
              </Button>
            )}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};
