import { Grid, Paper } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom"
import { useUserApplicationsContext } from "./Contexts/UserApplicationsContext";
import { useUserContext } from "./Contexts/UserContext";

interface UserApplicationViewProps {
}

export const UserApplicationView = ({ }: UserApplicationViewProps) => {
  const { user } = useUserContext();
  const { applicationName } = useParams<{ applicationName: string }>();
  const { userApplication, pickUserApplication } = useUserApplicationsContext();

  useEffect(() => {
    pickUserApplication(applicationName ?? '');
  }, [pickUserApplication, applicationName]);

  const { login, loginItems, question, prompts, passwordLength } = useMemo(() => {
    return { ...userApplication }
  }, [userApplication])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {userApplication ? (
            <>
              <h1>
                {applicationName ?? 'Nothing found'}
              </h1>
              <h3>
                Login
              </h3>
              {(loginItems!.length === 0) &&
                <p>
                  {login}
                </p>
              }
              {loginItems && loginItems.length > 0 &&
                <p>
                  Your username is made up of the {loginItems[0]} and {loginItems[1]} items in your memory room.
                </p>
              }
              <hr />
              <h3>
                Password
              </h3>
              <p>
                Question: <strong>{question}</strong>
              </p>
              <p>
                Hints: <strong>{prompts!.join(', ')}</strong>
              </p>
              <hr />
              <p>
                Your special symbol is &nbsp;<strong>{user!.symbols[0]}</strong>.
              </p>
              <p>
                Your magic number is &nbsp;<strong>{user!.magicNumber}</strong>.
              </p>
              <p>
                Your password is <strong>{passwordLength}</strong> characters long.
              </p>
            </>
          ) : (
            <p>
              No application selected.
            </p>
          )}
        </Paper>
      </Grid>
    </Grid>
  )

}