export const getOrdinal = (index: number) => {
  const text = index.toString();

  if (text.endsWith(`11`) || 
      text.endsWith(`12`) ||
      text.endsWith(`13`)) {
    return `${index}th`;
  }

  if (text.endsWith(`1`)) {
    return `${index}st`;
  }

  if (text.endsWith(`2`)) {
    return `${index}nd`;
  }

  if (text.endsWith(`3`)) {
    return `${index}rd`;
  }

  return `${index}th`;
}