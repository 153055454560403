import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import { Avatar, Button, Dialog, DialogTitle, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { useUserApplicationsContext } from './Contexts/UserApplicationsContext';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import LanguageIcon from '@mui/icons-material/Language';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const drawerWidth: number = 240;

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

interface DrawerButtonProps {
  icon: any;
  itemText: string;
  url: string;
}

const DrawerButton = ({ url, icon, itemText }: DrawerButtonProps) => {
  return (
    <Link
      to={url}
      style={{
        color: "initial",
        textDecoration: "none"
      }}>
      <ListItemButton>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={itemText} />
      </ListItemButton>
    </Link>
  );
}

interface DrawerProps {
  drawerOpen: boolean;
  toggleDrawer: () => void;
}

export const Drawer = ({ drawerOpen, toggleDrawer }: DrawerProps) => {
  const { userApplications, removeUserApplication } = useUserApplicationsContext();

  const [selectedApplicationName, setSelectedApplicationName] = useState<string>();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const handleDialogOpen = (applicationName: string) => {
    setSelectedApplicationName(applicationName);
    setConfirmationDialogOpen(true);
  };

  const handleDialogConfirm = () => {
    if (selectedApplicationName) {
      removeUserApplication(selectedApplicationName);
    }
    setConfirmationDialogOpen(false);
  };

  const handleDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  return (
    <StyledDrawer variant="permanent" open={drawerOpen}>
      <div style={{
        left: "1em",
        fontSize: "120%",
        position: "absolute",
        top: "1em",
        width: "50%"
      }}>
        Memory Vault
      </div>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <DrawerButton key={'home'} url='/' icon={<DashboardIcon />} itemText='Home' />
        <DrawerButton key={'settings'} url='/settings' icon={<SettingsIcon />} itemText='Settings' />
        <DrawerButton key={'new-password'} url='/new-password' icon={<AddIcon />} itemText='New Password' />
        <Divider sx={{ my: 1 }} />
        {
          userApplications.length > 0 &&
          userApplications.map(({ applicationName }) => {
            return (
              <ListItem
                key={`view-${applicationName}`}
                secondaryAction={
                  <IconButton edge='end' aria-label='delete' onClick={() => handleDialogOpen(applicationName)}>
                    <DeleteIcon />
                  </IconButton>
                }
                style={{ padding: 0, margin: 0 }}>
                <ListItemButton 
                  component={Link} 
                  onClick={() => setSelectedApplicationName(applicationName)}
                  selected={selectedApplicationName === applicationName}
                  to={`view-login-details/${applicationName}`}>
                  <ListItemAvatar>
                    <Avatar>
                      <LanguageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  {applicationName}
                </ListItemButton>
              </ListItem>
            );
          })
        }
      </List>
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby='alert-dialog-delete-application-title'
        aria-describedby='alert-dialog-delete-application-title-description'>
        <DialogTitle
          id='alert-dialog-delete-application-title'>
          Delete Application {selectedApplicationName}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-delete-application-title-description'>
            Are you sure you want to delete the password details for {selectedApplicationName}?
          </DialogContentText>
          <DialogActions>
            <Button onClick={handleDialogClose}>No</Button>
            <Button onClick={handleDialogConfirm} autoFocus>Yes</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </StyledDrawer >
  );
}
