import { Button, Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import { useUserContext } from "./Contexts/UserContext"

interface SettingsProps {
}

export const Settings = ({ }: SettingsProps) => {
  const { user } = useUserContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <h1>
            Settings
          </h1>
          <p>
            Your special symbol is <strong>{user?.symbols[0]}</strong>.
          </p>
          <p>
            Your magic number is <strong>{user?.magicNumber}</strong>.
          </p>
          <Button
            component={Link}
            to='/print-settings'
            target='__blank'
            variant='contained'
            style={{ marginTop: '1.5em', width: '15em' }}>
            Print these settings
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}