export const correlatives = [
  'What',
  'When',
  'Where',
  'Which',
  'Who'
]

export const modifiers = [
  'Is',
  'Was',
  'Will be',
]

export const articles = [
  'My',
  'Your',
  'His',
  'Her',
  'Its',
  'Our',
  'Their',
]

export const adjectives = [
  'Favourite',
  'Best',
  'Worst',
  'Biggest',
  'Smallest',
  'Happiest',
  'Saddest',
  'First',
  'Last',
  'Highest',
  'Lowest',
  'Thickest',
  'Thinnest',
]

export const nouns = [
  'Business',
  'Book',
  'Customer service',
  'Car',
  'Dress',
  'Equipment',
  'Fear',
  'Ice cream',
  'Home',
  'Memory',
  'Place',
  'Quote',
  'Shirt',
  'Shop',
  'Tag line',
  'Tool',
]

export const prompts = [
  'Area',
  'Amount',
  'City',
  'Colour',
  'Location',
  'Make',
  'Model',
  'Name',
  'Number',
  'Person',
  'Street',
  'Type',
]