import { FormControl, Grid, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { prompts } from "./constants";

interface DropdownPromptWithAnswerProps {
  index: number;
  prompt?: { prompt: number, answer: string };
  updateMap: (key: number, value: { prompt: number, answer: string }) => void;
}

export const DropdownPromptWithAnswer = ({ index, prompt, updateMap }: DropdownPromptWithAnswerProps) => {
  const [promptNumber, setPromptNumber] = useState(prompt?.prompt ?? 0);
  const [answer, setAnswer] = useState(prompt?.answer ?? '');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={4}>
        <Select
          id={`prompt-${index}`}
          value={promptNumber}
          fullWidth
          onChange={(event: any) => {
            setPromptNumber(event.target.value);
            updateMap(index, {prompt: event.target.value, answer});
          }}
        >
          {prompts.map((prompt: string, i: number) =>
            <MenuItem key={`${prompt}-${crypto.randomUUID()}`} value={i}>{prompt}</MenuItem>
          )}
        </Select>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        <FormControl variant='outlined' fullWidth style={{ marginBottom: "1em" }}>
          <TextField  
            id={`answer-${index}`}
            value={answer}
            label='Answer'
            aria-describedby='A memorable answer to the selected prompt'
            onChange={(event: any) => {
              setAnswer(event.target.value);
              updateMap(index, {prompt: promptNumber, answer: event.target.value});
            }} />
        </FormControl>
      </Grid>
    </Grid>
  );
}