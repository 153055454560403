import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Backdrop, Box, CircularProgress, Container, Grid, Paper } from "@mui/material";
import { Header } from "./Header";
import { Drawer } from "./Drawer";
import Toolbar from "@mui/material/Toolbar";
import { Home } from "./Home";
import { Settings } from "./Settings";
import { NewPassword } from "./NewPassword";
import { UserApplicationView } from "./UserApplicationView";
import { Copyright } from "./Copyright";
import { useUserContext } from "./Contexts/UserContext";
import { GoogleCredentialResponse, GoogleLogin } from "@react-oauth/google";
import { SettingsForPrinting } from "./SettingsForPrinting";

export const Main = () => {
  const { authenticateUser, loading, user } = useUserContext();
  const location = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(true);

  const responseMessage = (response: GoogleCredentialResponse) => {
    authenticateUser(response.credential);
  };

  const errorMessage = () => {
    console.log("An error occurred connecting to the Google API");
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (user === undefined) {
    return (
      <>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                width: "90%",
                maxWidth: 400,
              }}
            >
              <h1>Memory Vault</h1>
              <p>
                Welcome! Memory Vault is a new kind of password manager. Please
                log in with a Google account to continue.
              </p>
              <hr />
              <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
            </Paper>
          </Grid>
        </Grid>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    )
  } else if (location.pathname === '/print-settings') {
    return (
      <Routes>
        <Route path="/print-settings" element={<SettingsForPrinting />} />
      </Routes>
    )
  } else {
    return (
      <>
        <Header drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
        <Drawer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/new-password" element={<NewPassword />} />
              <Route
                path="/view-login-details/:applicationName"
                element={<UserApplicationView />}
              />
            </Routes>
            <Copyright style={{ marginTop: "5em" }} />
          </Container>
        </Box>
      </>
    )
  }
}
